import Vue from 'vue'
import App from './App.vue'
import router from './router'

import '@/assets/css/normalize.css'
import '@/assets/css/app.css'
import 'element-ui/lib/theme-chalk/icon.css'
import "swiper/dist/css/swiper.css"

import '@/plugins/periodfree.js'

import {
  Notification
} from 'element-ui'

Vue.prototype.$notify = Notification

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#root')