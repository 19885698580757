import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import(/* webpackChunkName: "home" */ '../views/index.vue'),
    },
    {
        path: '/mint',
        name: 'mint',
        component: () => import(/* webpackChunkName: "mint" */ '../views/mint.vue'),
    },
    {
        path: '/donate',
        name: 'donate',
        component: () => import(/* webpackChunkName: "donate" */ '../views/donate.vue'),
    },
    {
        path: '*',
        redirect: {
            path: '/'
        }
    }
]

const router = new VueRouter({
    mode: 'history', //去掉url中的#
    routes,
    scrollBehavior (to, from, savedPosition) {
        if (to.hash) {
            return {
            selector: to.hash
            }
        }else{
            return { 
            x: 0,
            y: 0
            }
        }
    }
})

export default router
