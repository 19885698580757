<template>
    <div id="root">
        <!--header-->
        <header class="flex items-center justify-center relative py-[15px] bg-[#f8f3eb]">
            <router-link class="flex items-center" to="/">
                <img src="@/assets/images/logo.png" width="168" height="46">
            </router-link>
            <div class="navbar">
                <i class="el-icon-menu text-[24px] m-icon-more" @click="toggleMenu"></i>
                <div class="flex items-center justify-center uppercase" :class="{'navbar-active':isShowMenu}" @click="isShowMenu=false">
                    <router-link class="text-[18px] text-black bold-500 px-[20px]" to="/mint">Mint</router-link>
                    <router-link class="text-[18px] text-black bold-500 px-[20px]" to="/donate">Donate</router-link>
                    <a href="https://twitter.com/PeriodFreeOrg" target="_blank" class="block ml-[20px] w-[20px] h-[20px]">
                        <img class="w-full" src="@/assets/images/twitter.svg">
                    </a>
                    <a href="https://opensea.io/collection/periodfree" target="_blank" class="block w-[30px] h-[30px] ml-[20px]">
                        <img class="w-full" src="@/assets/images/opensea.svg">
                    </a>
                </div>
            </div>
        </header>
        <transition name="lc">
            <router-view></router-view>
        </transition>
        <div class="py-[30px] text-center">
            <img src="@/assets/images/logo.png" width="168" height="46">
            <div class="flex items-center justify-center my-[5px]">
                <a href="https://twitter.com/PeriodFreeOrg" target="_blank" class="block w-[20px] h-[20px]">
                    <img class="w-full" src="@/assets/images/twitter.svg">
                </a>
                <a href="https://opensea.io/collection/periodfree" target="_blank" class="block w-[30px] h-[30px] ml-[10px]">
                    <img class="w-full" src="@/assets/images/opensea.svg">
                </a>
            </div>
            <p class="text-[12px]">© 2022 Periodfree All rights reserved</p>
        </div>
    </div>
</template>
<script>
export default {
    name: 'app',
    data() {
        return {
            isShowMenu: false
        }
    },
    methods: {
        toggleMenu() {
            this.isShowMenu = !this.isShowMenu
        }
    },
}
</script>